<template>
  <b-modal
    :id="id"
    size="lg"
    title="Đồng bộ doanh nghiệp"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    :ok-only="true"
    ok-variant="outline-primary"
    ok-title="Hủy"
  >
    <vue-good-table
      ref="user-table"
      class="table-async"
      style-class="vgt-table"
      :columns="columns"
      :rows="rows || []"
      :sort-options="{
        enabled: false,
      }"
      :select-options="{
        enabled: false,
      }"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column:Tên doanh nghiệp-->
        <span
          v-if="props.column.field === 'name'"
        >
          <span>{{ props.row.name }}</span>
        </span>

        <span v-else-if="props.column.field === 'affiliatedBusiness'">

          <div
            v-if="props.row.affiliatedBusiness"
            class="word-break text-center"
            :class="statusSync(props.row, props.row.affiliatedBusiness) || props.row.isSectorBussinessExist? 'badge-custom badge-danger': ''"
          >{{ props.row.affiliatedBusiness }}</div>
          <div
            v-else
            class="text-center"
          >-</div>

        </span>
        <span v-else-if="props.column.field === 'systemBusiness'">
          <div
            v-if="props.row.systemBusiness"
            class="word-break text-center"
            :class="statusSync(props.row, props.row.systemBusiness) || props.row.isSectorBussinessExist ? 'badge-custom badge-danger': ''"
          >{{ props.row.systemBusiness }}</div>
          <div
            v-else
            class="text-center"
          >-</div>
        </span>

      </template>
    </vue-good-table>
  </b-modal>
</template>

<script>

import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    VueGoodTable: () => import('@/components/table/VueGoodTable.vue'),
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    rows: {
      type: Array,
      default: _ => [],
    },
    totalRecord: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      columns: [
        {
          label: '',
          field: 'name',
          width: '40%',
        },
        {
          label: 'Dữ liệu LGSP',
          field: 'affiliatedBusiness',
          thClass: 'text-center',
          width: '30%',
        },
        {
          label: 'Dữ liệu hệ thống',
          field: 'systemBusiness',
          thClass: 'text-center',
          width: '30%',
        },
      ],
    }
  },
  methods: {
    statusSync(row, data) {
      if (row.affiliatedBusiness === row.systemBusiness || !data) {
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss">
.modal-detail-async-business {
  .table-async {
    .vgt-left-align {
      text-align: center !important;
    }
  }
}

.badge-custom {
    display: block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    white-space: unset;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s;
    padding: 0.3rem 0.5rem;
    border-radius: 0.358rem;
  }

</style>
